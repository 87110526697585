import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    dxc: {
      purple: {
        500: "#5f249f",
        600: "#330072",
      },
      gray: {
        400: "#d9d9d6",
        500: "#969696",
        600: "#63666a",
      },
    },
    primary: {
      50: "#f5e8ff",
      100: "#d9c0f4",
      200: "#be97e8",
      300: "#a36dde",
      400: "#8944d4",
      500: "#702bba",
      600: "#572192",
      700: "#3e1869",
      800: "#250d40",
      900: "#0e031a",
    },
  },
  styles: {
    global: {
      Input: {
        boxShadow: "none  !important",
      },
      TextArea: {
        boxShadow: "none  !important",
      }
    }
  }
});

export default theme;