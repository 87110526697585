import { Container, Stack, Box, Flex } from "@chakra-ui/react";
import { useLocation, Navigate } from "react-router-dom";
import FeedbackError from "../components/FeedbackError";
import FeedbackSuccess from "../components/FeedbackSuccess";

const Redirection = () => {
  const { state } = useLocation();

  if (!state?.fromApp) {
    return <Navigate to="/" />;
  }

  const redirectionType = state?.redirectionType;
  const redirectionCode = state?.redirectionCode;

  if (redirectionType === "error" || redirectionType === "success") {
    return (
      <Box height={"100vh"} bg="gray.100">
        <Container maxW={"2xl"} pt={20}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={8}
            py={{ base: 20, md: 36 }}
            bg="white"
          >
            {redirectionType === "success" ? (
              <FeedbackSuccess />
            ) : (
              <FeedbackError errorCode={redirectionCode} />
            )}
          </Stack>
        </Container>
      </Box>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Redirection;
