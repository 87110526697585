import { Flex, Heading, Text } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";

const FeedbackError = ({ errorCode }) => {
  return (
    <>
      <Flex justifyContent={"center"}>
        <AiOutlineClose color="red" size={100} />
      </Flex>

      <Heading
        fontWeight={600}
        fontSize={{ base: "3xl", sm: "4xl", md: "4xl" }}
      >
        Došlo k chybe
      </Heading>
      <Text color={"gray.500"}>
        {errorCode === "FEEDBACK_NOT_FOUND"
          ? "Táto spätná väzba neexistuje"
          : errorCode === "FEEDBACK_DEADLINE_EXPIRED"
          ? "Termím odoslania spätnej väzby vypršal"
          : "Prosím skúste to neskôr alebo sa obráťte na správcu"}
      </Text>
    </>
  );
};

export default FeedbackError;
