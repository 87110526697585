import { Flex, Heading, Text } from "@chakra-ui/react"
import { AiOutlineCheck } from "react-icons/ai";

const FeedbackSuccess = () => {
  return (
    <>
    <Flex justifyContent={"center"}>
      <AiOutlineCheck color="#6CC24A" size={100} />
    </Flex>

    <Heading
      fontWeight={600}
      fontSize={{ base: "3xl", sm: "4xl", md: "4xl" }}
    >
      Ďakujeme!
    </Heading>
    <Text color={"gray.500"}>
      Tvoja spätná väzba bola úspešne odoslaná
    </Text>
  </>
  )
}

export default FeedbackSuccess