import { Box, Container, Flex, Spinner } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import axios from "axios";

import FeedbackDetails from "../components/FeedbackDetails";
import FeedbackQuestionnaire from "../components/FeedbackQuestionnaire";
import { useNavigate, useParams } from "react-router-dom";

const Feedback = () => {
  const [feedback_info, setFeedback_info] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const feedbackId = params.feedbackId;

  useEffect(() => {
    axios
      .get(`/api/feedback/${feedbackId}`)
      .then((response) => {
        setFeedback_info(response.data);
      })
      .catch((error) => {
        // TODO could be done without if but no time to test if every error has correct response
        if (error.response.data.errorCode === "FEEDBACK_NOT_FOUND") {
          navigate("/");
        } else if (
          error.response.data.errorCode === "FEEDBACK_DEADLINE_EXPIRED"
        ) {
          navigate(`/feedback/${feedbackId}/redirect`, {
            state: {
              fromApp: true,
              redirectionType: "error",
              redirectionCode: "FEEDBACK_DEADLINE_EXPIRED",
            },
          });
        } else {
          navigate(`/feedback/${feedbackId}/redirect`, {
            state: {
              fromApp: true,
              redirectionType: "error",
              redirectionCode: "FEEDBACK_ERROR",
            },
          });
        }
      });
    // TODO carch error -> error page
  }, [feedbackId, navigate]);

  return (
    <Box w="100%" justify="center">
      <Flex
        w="100%"
        bgGradient="linear(to-r, primary.100, primary.300)"
        justify="center"
        align="center"
      >
        <FeedbackDetails zIndex="1" />
      </Flex>
      <Container maxW={"3xl"} mt="6" mb="6">
        {feedback_info ? (
          <FeedbackQuestionnaire questions={feedback_info?.questions} />
        ) : (
          <Flex justifyContent={"center"}>
            <Spinner color="dxc.purple.500" />
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default Feedback;
