import {
  Box,
  Heading,
  Image,
  Text,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import FeedbackImage from "../images/feedback_crop.png";

const FeedbackDetails = () => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Flex h="80%">
      <Box
        w={{ base: "90%", md: "lg" }}
        bg="dxc.purple.500"
        borderTopRightRadius="3xl"
        p="8"
        m="auto"
        mt="8"
        mb="8"
        position="relative"
      >
        <Heading as="h3" size="lg" color="white" fontWeight="semibold">
          Feedback and learning Organization
        </Heading>
        <Text color="white" mt="4" fontSize="2xl">
          Questionnaire
        </Text>
      </Box>
      {!isMobile && (
        <Image
          src={FeedbackImage}
          alt="feedback"
          maxH="60"
          sx={{
            WebkitMaskImage:
              "-webkit-gradient(linear, left center, right center, color-stop(0, transparent),color-stop(.10, rgba(0,0,0,.5)),color-stop(.25, black),color-stop(.75, black),color-stop(.90, rgba(0,0,0,.5)),color-stop(1, transparent));",
          }}
        />
      )}
    </Flex>
  );
};

export default FeedbackDetails;
